import { HashRouter } from "react-router-dom";
import { AllRoutes } from "./index";

const ElectronRoutes = () => {
  return (
    <HashRouter>
      <AllRoutes />
    </HashRouter>
  );
};

export default ElectronRoutes;

import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import CurrentUserContext from "../context/currentUserContext";

type PrivateRouteProps = {
  component: React.ComponentType,
  roles?: string,
};

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({
  component: RouteComponent,
  roles,
  ...rest
}: PrivateRouteProps) => {
  const location = useLocation();
  const { currentUser } = useContext(CurrentUserContext);

  // Look at this issue whhen there is an infinite loop with Navigate
  // https://github.com/remix-run/react-router/issues/8733

  if (!currentUser)
    return (
      <Navigate to={"/account/login"} state={{ from: location }} replace />
    );

  return <RouteComponent />;
};

export default PrivateRoute;

// import React, { useState, useEffect, useContext } from "react";
// import React, { useContext } from "react";
import InternetStatusContext from "../context/internetStatusContext";
import useOnlineStatus from "../hooks/useOnlineStatus";

// // Inspired by https://levelup.gitconnected.com/useonlinestatus-a-react-hook-to-know-when-your-app-is-offline-2d06e4e536a
// const PING_RESOURCE = "/ping.txt";
// const TIMEOUT_TIME_MS = 3000;
// const onlinePollingInterval = 10000;

// const timeout = (time, promise) => {
//   return new Promise(function (resolve, reject) {
//     setTimeout(() => {
//       reject(new Error("Request timed out."));
//     }, time);
//     promise.then(resolve, reject);
//   });
// };

// const checkInternetStatus = async () => {
//   const controller = new AbortController();
//   const { signal } = controller;

//   // If the browser has no network connection return offline
//   if (!navigator.onLine) return navigator.onLine;

//   //
//   try {
//     await timeout(
//       TIMEOUT_TIME_MS,
//       fetch(PING_RESOURCE, {
//         method: "GET",
//         signal,
//       })
//     );
//     return true;
//   } catch (error) {
//     // Error Log
//     console.error(error);

//     // This can be because of request timed out
//     // so we abort the request for any case
//     controller.abort();
//   }
//   return false;
// };

export const InternetStatusProvider = ({ children }) => {
  const online = useOnlineStatus();

  // const [internetStatus, setInternetStatus] = useState(true);

  // const checkStatus = async () => {
  //   const online = await checkInternetStatus();
  //   setInternetStatus(online);
  // };

  // useEffect(() => {
  //   window.addEventListener("offline", () => {
  //     setInternetStatus(false);
  //   });

  //   // Add polling incase of slow connection
  //   const id = setInterval(() => {
  //     checkStatus();
  //   }, onlinePollingInterval);

  //   return () => {
  //     window.removeEventListener("offline", () => {
  //       setInternetStatus(false);
  //     });

  //     clearInterval(id);
  //   };
  // }, []);

  return (
    <InternetStatusContext.Provider value={online}>
      {children}
    </InternetStatusContext.Provider>
  );
};

// export const useInternetStatus = () => {
//   const store = useContext(InternetStatusContext);
//   return store;
// };

// export default useInternetStatus;

import React from "react";

const CurrentUserContext = React.createContext({
  currentUser: null,
  appVersion: null,
  mostRecentAppVersion: null,
  loadingAppVersion: false,
  loadingMostRecentAppVersion: false,
  refeshRealmGroupsSubscriptions: () => {},
});
CurrentUserContext.displayName = "CurrentUserContext";

export default CurrentUserContext;

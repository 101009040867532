// @flow
import { apiClient } from "./apiCore";

function login(params: any): any {
  const baseUrl = "/login/";
  return apiClient.create(`${baseUrl}`, params);
}

function logout(): any {
  const baseUrl = "/logout/";
  return apiClient.create(`${baseUrl}`, {});
}

function signup(params: any): any {
  const baseUrl = "/register/";
  return apiClient.create(`${baseUrl}`, params);
}

function forgotPassword(params: any): any {
  const baseUrl = "/send_password_reset_instructions/";
  return apiClient.get(`${baseUrl}`, params);
}

function forgotPasswordConfirm(params: any): any {
  const baseUrl = "/password/reset/confirm/";
  return apiClient.create(`${baseUrl}`, params);
}

export { login, logout, signup, forgotPassword, forgotPasswordConfirm };

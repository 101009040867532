import { realmApp } from "./realm";
import { logger } from "../logger";
import { mdbErrors } from "../mongodb";

// TODO: We should put all this in a realmAppVersion object
// and include the schema

export function dbGetAppVersions(version, os, limit) {
  logger.info("In dbGetAppVersions");
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  return realm.getAppVersions(version, os, limit);
}

export function dbGetAppVersion(version, os) {
  logger.info("In dbGetAppVersion");
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  return new Promise(async (resolve, reject) => {
    try {
      const response = await dbGetAppVersions(version, os, 1);
      if (response && response.length > 0) {
        resolve(response[0]);
      } else {
        logger.error(mdbErrors.NOT_FOUND_APP_VERSION);
        reject(mdbErrors.NOT_FOUND_APP_VERSION);
      }
    } catch (error) {
      logger.error(error);
      reject(error);
    }
  });
}

export function dbGetMostRecentAppVersions(os, limit = 1) {
  logger.info("In dbGetMostRecentAppVersions");
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  return realm.getOsMostRecentAppVersions(os, limit);
}

export function dbGetMostRecentAppVersion(os) {
  logger.info("In dbGetMostRecentAppVersion");
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  return new Promise(async (resolve, reject) => {
    try {
      const response = await dbGetMostRecentAppVersions(os, 1);
      if (response && response.length > 0) {
        resolve(response[0]);
      } else {
        logger.error(mdbErrors.NOT_FOUND_APP_VERSION);
        reject(mdbErrors.NOT_FOUND_APP_VERSION);
      }
    } catch (error) {
      logger.error(error);
      reject(error);
    }
  });
}

// export function dbCreateAppVersion(appVersion) {
//   logger.info("In dbCreateAppVersion");
//   const realm = realmApp?.currentUser?.functions; if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

//   const now = new Date();
//   appVersion["createdAt"] = now;

//   return realm.createAppVersion(appVersion);
// }

export function dbIncrementLegitDownloadCount(
  by,
  appVersionId,
  appVersionBuildId
) {
  logger.info("In dbIncrementLegitDownloadCount");
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  return realm.incrementLegitDownloadCount(by, appVersionId, appVersionBuildId);
}

// export function dbIncrementBotDownloadCount(
//   by,
//   appVersionId,
//   appVersionBuildId
// ) {
//   logger.info("In dbIncrementBotDownloadCount");
//   const realm = realmApp?.currentUser?.functions; if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

//   return realm.incrementBotDownloadCount(
//     by,
//     appVersionId,
//     appVersionBuildId
//   );
// }

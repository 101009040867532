import * as Sentry from "@sentry/react";

import conf from "../../config";

const environment = conf.CONFIG_APP_ENV;
const isDevelopment = environment === "development";
const dsn = conf.CONFIG_APP_SENTRY_DSN;

export function initSentryWebSDK() {
  Sentry.init({
    // Moved this to env variable but it can be public
    dsn: dsn,
    environment: environment,
    // Don't use the default integrations when developing (prevents overrides of console.log)
    defaultIntegrations: isDevelopment ? false : undefined,

    integrations: [new Sentry.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

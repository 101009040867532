import { useContext, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import MongodbContext from "../context/mongodbContext";
import CurrentUserContext from "../context/currentUserContext";
import ErrorEndOfLife from "../components/pages/ErrorEndOfLife";
import conf from "../config";
import { queryKeys } from "../services/caching";
import { appBuild } from "../services/os";
import { logger } from "../services/logger";

/* Search replace to perform to switch web/electron: /services/web/ */
import { addRealmGroupSubscription } from "../services/web/realmFlexSyncSubscriptions";
import { dbGetUserByLegacyId } from "../services/web/user";
import {
  dbGetAppVersion,
  dbGetMostRecentAppVersion,
} from "../services/web/appVersion";

export const CurrentUserProvider = ({ children }) => {
  const mongodbContext = useContext(MongodbContext);
  const isGroupSubscriptionAdded = useRef(
    conf.CONFIG_APP_BUILD !== appBuild.WEB ? false : true
  );
  const realmUserProfile =
    mongodbContext.providerType === "custom-token"
      ? mongodbContext?.realmApp?.currentUser?.profile
      : null;
  let currentUser = null;

  const { data: appVersion, isLoading: loadingAppVersion } = useQuery({
    enabled:
      !!mongodbContext?.realmApp?.currentUser?.id &&
      conf.CONFIG_APP_BUILD !== appBuild.WEB,
    placeholderData: null,
    queryKey: [queryKeys.APP_VERSION, conf.CONFIG_APP_VERSION],
    queryFn: () =>
      dbGetAppVersion(conf.CONFIG_APP_VERSION, conf.CONFIG_APP_BUILD),
  });

  const { data: mostRecentAppVersion, isLoading: loadingMostRecentAppVersion } =
    useQuery({
      enabled:
        !!mongodbContext?.realmApp?.currentUser?.id &&
        conf.CONFIG_APP_BUILD !== appBuild.WEB,
      placeholderData: null,
      queryKey: [queryKeys.APP_VERSION_MOST_RECENT, "node"],
      queryFn: () => dbGetMostRecentAppVersion(conf.CONFIG_APP_BUILD),
    });

  const { data: user, isLoading: isLoadingUser } = useQuery({
    enabled: !!realmUserProfile?.id,
    queryKey: [queryKeys.USER, realmUserProfile?.slug],
    queryFn: () => dbGetUserByLegacyId(realmUserProfile?.id),
  });

  const refeshRealmGroupsSubscriptions = () => {
    if (conf.CONFIG_APP_BUILD === appBuild.WEB) return null;

    /**
     *
     * Sometimes it blocks, what seems to resolve the issue is to
     * remove the subscription and increment the version number
     */
    addRealmGroupSubscription(mongodbContext.realm, currentUser)
      .then(() => {
        logger.info("Realm groups subscriptions refreshed!");
        isGroupSubscriptionAdded.current = true;
      })
      .catch((error) => {
        logger.error(error);
      });
  };

  if (conf.CONFIG_APP_BUILD !== appBuild.WEB) {
    if (loadingAppVersion) return <p>Checking app version...</p>;
    if (loadingMostRecentAppVersion) return <p>Checking for updates...</p>;
    if (appVersion?.isEndOfLife) return ErrorEndOfLife();
  }

  if (realmUserProfile) {
    if (isLoadingUser) return <p>Loading user...</p>;

    if (user) {
      currentUser = {
        ...user,
        id: realmUserProfile.id,
        avatarUrl: realmUserProfile.avatarUrl,
        groupIds: user.groupIds || [],
        groupDbIds: user.groupDbIds || [],
      };

      if (isGroupSubscriptionAdded.current === false) {
        refeshRealmGroupsSubscriptions();
      }
    }
  }

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser,
        appVersion,
        mostRecentAppVersion,
        loadingAppVersion,
        loadingMostRecentAppVersion,
        refeshRealmGroupsSubscriptions,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};

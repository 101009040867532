import React from "react";

const MongodbContext = React.createContext({
  realm: null,
  realmApp: null,
  providerType: null,
});
MongodbContext.displayName = "MongodbContext";

export default MongodbContext;

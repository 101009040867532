import axios from "axios";
import Cookies from "universal-cookie";
import conf from "../../config";
import { appBuild } from "../../services/os";

/* Search replace to perform to switch web/electron: /services/web/ */
import { realmApp } from "../../services/web/realm";

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.baseURL = conf.API_URL;

/* before a request */
axios.interceptors.request.use((config) => {
  const token = getAuthTokenCookieContent();
  if (token) {
    /* This sets the required header for authenticating on IH api */
    config.headers.common["Authorization"] = "JWT " + token;
    // config.headers.common["x-auth-token"] = token;
  }
  return config;
});

/* intercepting to capture errors */
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;

    if (error && error.response && error.response.status === 404) {
      // window.location.href = '/not-found';
    } else if (error && error.response && error.response.status === 403) {
      window.location.href = "/access-denied";
    } else {
      if (error.response)
        switch (error.response.status) {
          case 401:
            message = "Invalid credentials.";
            break;
          case 403:
            message = "Access Forbidden.";
            break;
          case 404:
            message = "Sorry! the data you are looking for could not be found.";
            break;
          default: {
            message =
              error.response && error.response.data
                ? error.response.data["message"]
                : error.message || error;
          }
        }
      return Promise.reject(message);
    }
  }
);

function getAuthTokenCookieContent() {
  const cookies = new Cookies();
  let token = cookies.get(conf.CONFIG_AUTH_TOKEN_KEY);
  /* That is something the cookie could return
  if things go wrong */
  if (token && token !== "undefined") return token;

  if (conf.CONFIG_APP_BUILD !== appBuild.WEB) {
    token = window.localStorage.getItem(conf.CONFIG_AUTH_TOKEN_KEY);
    if (token && token !== "undefined") return token;
  }

  return null;
}

class APICore {
  isAuthCookieSet() {
    if (getAuthTokenCookieContent()) return true;

    return false;
  }

  /**
   * Fetches data from given url
   */
  get = (url, params) => {
    let response;
    if (params) {
      var queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }
    return response;
  };

  getFile = (url, params) => {
    let response;
    if (params) {
      var queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
      response = axios.get(`${url}?${queryString}`, { responseType: "blob" });
    } else {
      response = axios.get(`${url}`, { responseType: "blob" });
    }
    return response;
  };

  getMultiple = (urls, params) => {
    const reqs = [];
    let queryString = "";
    if (params) {
      queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
    }

    for (const url of urls) {
      reqs.push(axios.get(`${url}?${queryString}`));
    }
    return axios.all(reqs);
  };

  /**
   * post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data);
  };

  /**
   * Updates patch data
   */
  updatePatch = (url, data) => {
    return axios.patch(url, data);
  };

  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.put(url, data);
  };

  /**
   * Deletes data
   */
  delete = (url, options = {}) => {
    return axios.delete(url, options);
  };

  /**
   * post given data to url with file
   */
  createWithFile = (url, data) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axios.post(url, formData, config);
  };

  /**
   * post given data to url with file
   */
  updateWithFile = (url, data) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axios.patch(url, formData, config);
  };

  /**
   * Returns the logged in user
   */
  getLoggedInUser = () => {
    return realmApp?.currentUser?.profile || null;
  };
}

const apiClient = new APICore();

export { apiClient, getAuthTokenCookieContent };

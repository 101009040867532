import React, { Suspense } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PrivateRoute from "./PrivateRoute";
import Spinner from "../components/Spinner";
import * as layoutConstants from "../constants/layout";

// All layouts/containers
import DefaultLayout from "../layouts/Default";
import VerticalLayout from "../layouts/Vertical";
import DetachedLayout from "../layouts/Detached";
import HorizontalLayout from "../layouts/Horizontal";
import FullLayout from "../layouts/Full";

// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/account/Login"));
const Logout = React.lazy(() => import("../pages/account/Logout"));
const LogoutWarning = React.lazy(() =>
  import("../pages/account/LogoutWarning")
);
// const Register = React.lazy(() => import("../pages/account/Register"));
const Confirm = React.lazy(() => import("../pages/account/Confirm"));
const ForgetPassword = React.lazy(() =>
  import("../pages/account/ForgetPassword")
);
// const LockScreen = React.lazy(() => import("../pages/account/LockScreen"));
// const FAQ = React.lazy(() => import("../pages/FAQ"));
// const Help = React.lazy(() => import("../pages/Help"));
const V3Changes = React.lazy(() => import("../pages/V3Changes"));

// pages
const Maintenance = React.lazy(() => import("../components/pages/maintenance"));

const ErrorPageNotFound = React.lazy(() =>
  import("../components/pages/errorPageNotFound")
);
const ServerError = React.lazy(() => import("../components/pages/serverError"));

const AccessDenied = React.lazy(() =>
  import("../components/pages/accessDenied")
);

const Preparation = React.lazy(() => import("../components/pages/preparation"));
const Business = React.lazy(() => import("../components/pages/business"));

const Contact = React.lazy(() => import("../components/pages/contact"));
const About = React.lazy(() => import("../components/pages/about"));
const Download = React.lazy(() => import("../components/pages/download"));

const Pricing = React.lazy(() => import("../components/pages/pricing"));
const Support = React.lazy(() => import("../components/pages/support"));
const PrivacyPolicy = React.lazy(() =>
  import("../components/pages/privacyPolicy")
);
const TermsOfService = React.lazy(() =>
  import("../components/pages/termsOfService")
);

/// resources

// Group
const Group = React.lazy(() => import("../components/resources/groups/Group"));
const GroupsHome = React.lazy(() =>
  import("../components/resources/groups/GroupsHome")
);
const NewGroup = React.lazy(() =>
  import("../components/resources/groups/NewGroup")
);
const GroupsHelp = React.lazy(() =>
  import("../components/resources/groups/GroupsHelp")
);
// const GroupSettings = React.lazy(() =>
//   import("../components/resources/groups/GroupSettings")
// );
const GroupSettings = React.lazy(() =>
  import("../components/resources/groups/settings/index")
);
const GroupPricing = React.lazy(() =>
  import("../components/resources/groups/GroupPricing")
);
const GroupMembersManager = React.lazy(() =>
  import("../components/resources/groups/members/GroupMembersManager")
);
const GroupGlossaries = React.lazy(() =>
  import("../components/resources/groups/glossaries/GroupGlossaries")
);
const GroupUploads = React.lazy(() =>
  import("../components/resources/uploads/GroupUploads")
);
const NewGroupGlossary = React.lazy(() =>
  import("../components/resources/glossaries/NewGroupGlossary")
);

// Job
const Job = React.lazy(() => import("../components/resources/jobs/job"));
const Jobs = React.lazy(() =>
  import("../components/resources/jobs/listing/index")
);
const NewJob = React.lazy(() => import("../components/resources/jobs/newJob"));
const EditJob = React.lazy(() =>
  import("../components/resources/jobs/editJob")
);
const BusinessCalendar = React.lazy(() =>
  import("../components/resources/jobs/calendar")
);

// Client
const Client = React.lazy(() =>
  import("../components/resources/clients/client")
);
const Clients = React.lazy(() =>
  import("../components/resources/clients/clients")
);
const NewClient = React.lazy(() =>
  import("../components/resources/clients/newClient")
);
const EditClient = React.lazy(() =>
  import("../components/resources/clients/editClient")
);

// Transcription
const Transcription = React.lazy(() =>
  import("../components/resources/transcriptions/Transcription")
);
const Transcriptions = React.lazy(() =>
  import("../components/resources/transcriptions/Transcriptions")
);
const NewTranscription = React.lazy(() =>
  import("../components/resources/transcriptions/NewTranscription")
);
const EditTranscription = React.lazy(() =>
  import("../components/resources/transcriptions/EditTranscription")
);

// Upload
// const Uploads = React.lazy(() =>
//   import("../components/resources/uploads/uploads")
// );
const UserUploads = React.lazy(() =>
  import("../components/resources/uploads/UserUploads")
);
const NewUserUpload = React.lazy(() =>
  import("../components/resources/uploads/NewUserUpload")
);
const NewGroupUpload = React.lazy(() =>
  import("../components/resources/uploads/NewGroupUpload")
);

// Glossary
// const Glossary = React.lazy(() =>
//   import("../components/resources/glossaries/Glossary")
// );
const UserGlossary = React.lazy(() =>
  import("../components/resources/glossaries/UserGlossary")
);
const GroupGlossary = React.lazy(() =>
  import("../components/resources/glossaries/GroupGlossary")
);

const Glossaries = React.lazy(() =>
  import("../components/resources/glossaries/listing/index")
);
const NewUserGlossary = React.lazy(() =>
  import("../components/resources/glossaries/NewUserGlossary")
);
// const GlossaryFarm = React.lazy(() =>
//   import("../components/resources/glossaries/glossaryFarm")
// );
// const Database = React.lazy(() => import("../components/database"));

const UserDatabase = React.lazy(() =>
  import("../components/resources/termDatabase/UserDatabase")
);
const GroupDatabase = React.lazy(() =>
  import("../components/resources/termDatabase/GroupDatabase")
);

// Deck
const Deck = React.lazy(() => import("../components/resources/decks/deck"));
const Decks = React.lazy(() => import("../components/resources/decks/decks"));
const NewDeck = React.lazy(() =>
  import("../components/resources/decks/newDeck")
);
const EditDeck = React.lazy(() =>
  import("../components/resources/decks/editDeck")
);
// const PracticeDeck = React.lazy(() =>
//   import("../components/resources/decks/practiceDeck")
// );

// Deck
// const EntryReviewSession = React.lazy(() =>
//   import("../components/resources/entryReviewSessions/entryReviewSession")
// );
// const EntryReviewSessions = React.lazy(() =>
//   import("../components/resources/entryReviewSessions/entryReviewSessions")
// );

const ReviewDueEntries = React.lazy(() =>
  import("../components/resources/decks/ReviewDueEntries")
);
const ReviewNewEntries = React.lazy(() =>
  import("../components/resources/decks/LearnNewEntries")
);
const BrowseRandomEntries = React.lazy(() =>
  import("../components/resources/decks/BrowseRandomEntries")
);
const BrowseReviewedEntries = React.lazy(() =>
  import("../components/resources/decks/BrowseRandomReviewedEntries")
);

// Users
const UserProfile = React.lazy(() =>
  import("../components/resources/users/UserProfile")
);
const UserGroups = React.lazy(() =>
  import("../components/resources/users/UserGroups")
);
const UserDashboard = React.lazy(() =>
  import("../components/resources/users/dashboard/index")
);
const UserAccount = React.lazy(() =>
  import("../components/resources/users/account/index")
);
const UserNetwork = React.lazy(() =>
  import("../components/resources/users/network/index")
);

// Subscriptions
const SubscriptionSucceeded = React.lazy(() =>
  import("../components/resources/subscriptions/SubscriptionSucceeded")
);
const SubscriptionFailed = React.lazy(() =>
  import("../components/resources/subscriptions/SubscriptionFailed")
);
const GroupSubscriptionSucceeded = React.lazy(() =>
  import("../components/resources/groups/subscriptions/SubscriptionSucceeded")
);
const GroupSubscriptionFailed = React.lazy(() =>
  import("../components/resources/groups/subscriptions/SubscriptionFailed")
);

const loading = () => (
  <div className="d-flex justify-content-center" role="status">
    <span className="visually-hidden">Loading...</span>
    <Spinner className="text-primary m-2" color="primary" size={"lg"} />
  </div>
);

type LoadComponentProps = {
  component: React.LazyExoticComponent<() => JSX.Element>,
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
  <Suspense fallback={loading()}>
    <Component />
  </Suspense>
);

const AllRoutes = () => {
  const { layout } = useSelector((state) => ({
    layout: state.Layout,
  }));

  const getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (layout.layoutType) {
      case layoutConstants.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      case layoutConstants.LAYOUT_DETACHED:
        layoutCls = DetachedLayout;
        break;
      case layoutConstants.LAYOUT_FULL:
        layoutCls = FullLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };
  let Layout = getLayout();

  return useRoutes([
    { path: "/", element: <Navigate to={"/dashboard"} /> },
    {
      // public routes
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "g/:slug",
          element: <LoadComponent component={UserGlossary} />,
        },
        {
          path: "users/:creator_slug/glossaries/:slug",
          element: <LoadComponent component={UserGlossary} />,
        },
        {
          path: "about",
          element: <LoadComponent component={About} />,
        },
        {
          path: "contact",
          element: <LoadComponent component={Contact} />,
        },
        {
          path: "support",
          element: <LoadComponent component={Support} />,
        },
        {
          path: "privacy-policy",
          element: <LoadComponent component={PrivacyPolicy} />,
        },
        {
          path: "terms-of-service",
          element: <LoadComponent component={TermsOfService} />,
        },
        {
          path: "pricing",
          element: <LoadComponent component={Pricing} />,
        },
        // {
        //   path: "faq",
        //   element: <LoadComponent component={FAQ} />,
        // },
        // {
        //   path: "help",
        //   element: <LoadComponent component={Help} />,
        // },
        {
          path: "changes-overview",
          element: <LoadComponent component={V3Changes} />,
        },
      ],
    },
    {
      // public routes with their own layout
      path: "/",
      element: <DefaultLayout />,
      children: [
        {
          path: "not-found",
          element: <LoadComponent component={ErrorPageNotFound} />,
        },
        {
          path: "server-error",
          element: <LoadComponent component={ServerError} />,
        },
        {
          path: "access-denied",
          element: <LoadComponent component={AccessDenied} />,
        },
        {
          path: "site-in-maintenance",
          element: <LoadComponent component={Maintenance} />,
        },
        {
          path: "account",
          element: <LoadComponent component={ErrorPageNotFound} />,
        },
        {
          path: "account",
          children: [
            { path: "login", element: <LoadComponent component={Login} /> },
            // {
            //   path: "register",
            //   element: <LoadComponent component={Register} />,
            // },
            { path: "confirm", element: <LoadComponent component={Confirm} /> },
            {
              path: "forget-password",
              element: <LoadComponent component={ForgetPassword} />,
            },
            // {
            //   path: "lock-screen",
            //   element: <LoadComponent component={LockScreen} />,
            // },
            {
              path: "logout-warning",
              element: <LoadComponent component={LogoutWarning} />,
            },
            { path: "logout", element: <LoadComponent component={Logout} /> },
            {
              path: "*",
              element: <LoadComponent component={ErrorPageNotFound} />,
            },
          ],
        },
        { path: "*", element: <LoadComponent component={ErrorPageNotFound} /> },
      ],
    },
    {
      // auth protected routes
      path: "/",
      element: <PrivateRoute component={Layout} />,
      // element: <PrivateRoute roles={"Admin"} component={Layout} />,
      children: [
        {
          path: "dashboard",
          element: <LoadComponent component={UserDashboard} />,
        },
        {
          path: "my-account",
          element: <LoadComponent component={UserAccount} />,
        },
        {
          path: "business",
          element: <LoadComponent component={Business} />,
        },
        {
          path: "preparation",
          element: <LoadComponent component={Preparation} />,
        },
        {
          path: "groups",
          element: <LoadComponent component={UserGroups} />,
        },
        {
          path: "glossaries",
          element: <LoadComponent component={Glossaries} />,
        },
        {
          path: "my_database",
          element: <LoadComponent component={UserDatabase} />,
        },
        {
          path: "users/:creator_slug/glossaries/:slug",
          element: <LoadComponent component={UserGlossary} />,
        },
        {
          path: "glossaries/new",
          element: <LoadComponent component={NewUserGlossary} />,
        },
        {
          path: "decks_of_flashcards",
          element: <LoadComponent component={Decks} />,
        },
        {
          path: "users/:creator_slug/decks/:slug",
          element: <LoadComponent component={Deck} />,
        },
        {
          path: "users/:creator_slug/decks_of_flashcards/:slug",
          element: <LoadComponent component={Deck} />,
        },
        {
          path: "decks_of_flashcards/new",
          element: <LoadComponent component={NewDeck} />,
        },
        {
          path: "users/:creator_slug/decks/:slug/edit",
          element: <LoadComponent component={EditDeck} />,
        },
        {
          path: "users/:creator_slug/decks_of_flashcards/:slug/edit",
          element: <LoadComponent component={EditDeck} />,
        },
        {
          path: "users/:creator_slug/decks/:slug/review_cards_due",
          element: <LoadComponent component={ReviewDueEntries} />,
        },
        {
          path: "users/:creator_slug/decks_of_flashcards/:slug/review_cards_due",
          element: <LoadComponent component={ReviewDueEntries} />,
        },
        // {
        //   path: "users/:creator_slug/decks/:slug/review_new",
        //   element: <LoadComponent component={ReviewNewEntries} />,
        // },
        // {
        //   path: "users/:creator_slug/decks_of_flashcards/:slug/review_new",
        //   element: <LoadComponent component={ReviewNewEntries} />,
        // },
        {
          path: "users/:creator_slug/decks/:slug/browse_all_random",
          element: <LoadComponent component={BrowseRandomEntries} />,
        },
        {
          path: "users/:creator_slug/decks_of_flashcards/:slug/browse_all_random",
          element: <LoadComponent component={BrowseRandomEntries} />,
        },
        {
          path: "users/:creator_slug/decks/:slug/browse_reviewed_random",
          element: <LoadComponent component={BrowseReviewedEntries} />,
        },
        {
          path: "users/:creator_slug/decks_of_flashcards/:slug/browse_reviewed_random",
          element: <LoadComponent component={BrowseReviewedEntries} />,
        },
        // {
        //   path: "users/:creator_slug/decks/:slug/practice",
        //   element: <LoadComponent component={PracticeDeck} />,
        // },
        // {
        //   path: "users/:creator_slug/decks_of_flashcards/:slug/practice",
        //   element: <LoadComponent component={PracticeDeck} />,
        // },
        // {
        //   path: "users/:creator_slug/entry_review_sessions/:slug",
        //   element: <LoadComponent component={ReviewNewEntries} />,
        // },
        // {
        //   path: "users/:creator_slug/browse_random_cards_session/:slug",
        //   element: <LoadComponent component={BrowseRandomEntries} />,
        // },
        {
          path: "users/:creator_slug/flashcard_learning_sessions/:slug",
          element: <LoadComponent component={ReviewNewEntries} />,
        },
        {
          path: "users/:creator_slug/flashcard_browse_sessions/:slug",
          element: <LoadComponent component={BrowseRandomEntries} />,
        },
        {
          path: "my_network",
          element: <LoadComponent component={UserNetwork} />,
        },
        {
          path: "network",
          element: <LoadComponent component={UserNetwork} />,
        },
        {
          path: "my_storage",
          element: <LoadComponent component={UserUploads} />,
        },
        {
          path: "storage",
          element: <LoadComponent component={UserUploads} />,
        },
        {
          path: "storage/new",
          element: <LoadComponent component={NewUserUpload} />,
        },
        {
          path: "my_jobs",
          element: <LoadComponent component={Jobs} />,
        },
        {
          path: "jobs",
          element: <LoadComponent component={Jobs} />,
        },
        {
          path: "users/:creator_slug/jobs/:slug",
          element: <LoadComponent component={Job} />,
        },
        {
          path: "jobs/new",
          element: <LoadComponent component={NewJob} />,
        },
        {
          path: "users/:creator_slug/jobs/:slug/edit",
          element: <LoadComponent component={EditJob} />,
        },
        {
          path: "my_clients",
          element: <LoadComponent component={Clients} />,
        },
        {
          path: "clients",
          element: <LoadComponent component={Clients} />,
        },
        {
          path: "users/:creator_slug/clients/:slug",
          element: <LoadComponent component={Client} />,
        },
        {
          path: "clients/new",
          element: <LoadComponent component={NewClient} />,
        },
        {
          path: "users/:creator_slug/clients/:slug/edit",
          element: <LoadComponent component={EditClient} />,
        },
        {
          path: "my_transcripts",
          element: <LoadComponent component={Transcriptions} />,
        },
        {
          path: "transcripts",
          element: <LoadComponent component={Transcriptions} />,
        },
        {
          path: "users/:creator_slug/transcripts/:slug",
          element: <LoadComponent component={Transcription} />,
        },
        {
          path: "transcripts/new",
          element: <LoadComponent component={NewTranscription} />,
        },
        {
          path: "users/:creator_slug/transcripts/:slug/edit",
          element: <LoadComponent component={EditTranscription} />,
        },
        {
          path: "my_calendar",
          element: <LoadComponent component={BusinessCalendar} />,
        },
        {
          path: "calendar",
          element: <LoadComponent component={BusinessCalendar} />,
        },
        {
          path: "subscription-succeeded",
          element: <LoadComponent component={SubscriptionSucceeded} />,
        },
        {
          path: "subscription-failed",
          element: <LoadComponent component={SubscriptionFailed} />,
        },
        // {
        //   path: "tools",
        //   children: [
        //     {
        //       path: "ocr",
        //       element: <LoadComponent component={Tools} />,
        //     },
        //   ],
        // },
        {
          path: "groups",
          children: [
            {
              path: "new",
              element: <LoadComponent component={NewGroup} />,
            },
            {
              path: "help",
              element: <LoadComponent component={GroupsHelp} />,
            },
            {
              path: "home",
              element: <LoadComponent component={GroupsHome} />,
            },
            {
              path: ":group_slug",
              element: <LoadComponent component={Group} />,
            },
            {
              path: ":group_slug/settings",
              element: <LoadComponent component={GroupSettings} />,
            },
            {
              path: ":group_slug/pricing",
              element: <LoadComponent component={GroupPricing} />,
            },
            {
              path: ":group_slug/members",
              element: <LoadComponent component={GroupMembersManager} />,
            },
            {
              path: ":group_slug/database",
              element: <LoadComponent component={GroupDatabase} />,
            },
            {
              path: ":group_slug/glossaries",
              element: <LoadComponent component={GroupGlossaries} />,
            },
            {
              path: ":group_slug/glossaries/new",
              element: <LoadComponent component={NewGroupGlossary} />,
            },
            {
              path: ":group_slug/glossaries/:slug",
              element: <LoadComponent component={GroupGlossary} />,
            },
            // {
            //   path: ":group_slug/jobs",
            //   element: <LoadComponent component={GroupJobs} />,
            // },
            // {
            //   path: ":group_slug/clients",
            //   element: <LoadComponent component={GroupClients} />,
            // },
            {
              path: ":group_slug/storage",
              element: <LoadComponent component={GroupUploads} />,
            },
            {
              path: ":group_slug/storage/new",
              element: <LoadComponent component={NewGroupUpload} />,
            },
            {
              path: ":group_slug/subscription-succeeded",
              element: <LoadComponent component={GroupSubscriptionSucceeded} />,
            },
            {
              path: ":group_slug/subscription-failed",
              element: <LoadComponent component={GroupSubscriptionFailed} />,
            },
            // {
            //   path: "calendar",
            //   element: <LoadComponent component={BusinessCalendar} />,
            // },
          ],
        },
        {
          path: "community",
          children: [
            {
              path: "users/:slug",
              element: <LoadComponent component={UserProfile} />,
            },
          ],
        },
        {
          path: "download",
          element: <LoadComponent component={Download} />,
        },
        // {
        //   path: "faq",
        //   element: <LoadComponent component={FAQ} />,
        // },
        // {
        //   path: "help",
        //   element: <LoadComponent component={Help} />,
        // },
        { path: "*", element: <LoadComponent component={ErrorPageNotFound} /> },
      ],
    },
  ]);
};

export { AllRoutes };

/**
 * import "react-app-polyfill/stable" must be the first import in src/index.js
 */
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import "./i18n";
import App from "./App";
import { configureStore } from "./redux/store";

/* Search replace to perform to switch web/electron: /services/web/ */
import { initSentryWebSDK } from "./services/web/sentry";

initSentryWebSDK();

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={configureStore({})}>
        <App />
      </Provider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

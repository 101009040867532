import { realmApp } from "./realm";
import { ObjectId } from "bson";
import { logger } from "../logger";
import { mdbErrors } from "../mongodb";

// TODO: We should put all this in a realmUser object
// and include the schema

export function dbGetUserByLegacyId(legacyId) {
  logger.info("In dbGetUserByLegacyId");
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  return realm.getUserByLegacyId(legacyId);
}

export function dbIncrementUserConsumptionBytesUsageBy(userId, by) {
  logger.info("In dbIncrementUserConsumptionBytesUsageBy");
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  return realm.incrementUserConsumptionBytesUsageBy(userId, by);
}

export function dbIncrementUserProcessingBytesUsageBy(userId, by) {
  logger.info("In dbIncrementUserProcessingBytesUsageBy");
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  return realm.incrementUserProcessingBytesUsageBy(userId, by);
}

export function dbIncrementUserAcquiredStorageBytesBy(userId, by) {
  logger.info("In dbIncrementUserAcquiredStorageBytesBy");
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  return realm.incrementUserAcquiredStorageBytesBy(userId, by);
}

export function dbIncrementUserAcquiredProcessingBytesBy(userId, by) {
  logger.info("In dbIncrementUserAcquiredProcessingBytesBy");
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  return realm.incrementUserAcquiredProcessingBytesBy(userId, by);
}

export function dbAddFromGroupSubscriptionToUser(userId, subscription) {
  logger.info("In dbAddFromGroupSubscriptionToUser");
  logger.info("userId", userId);
  logger.info("subscription", subscription);
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  if (!userId) {
    logger.error(mdbErrors.UNDEFINED_USER);
    return Promise.reject(mdbErrors.UNDEFINED_USER);
  }

  return realm.addFromGroupSubscriptionToUser(ObjectId(userId), subscription);
}

export function dbRemoveUserFromGroupSubscriptionForGroup(userId, groupId) {
  logger.info("In dbRemoveUserFromGroupSubscriptionForGroup");
  logger.info("userId", userId);
  logger.info("groupId", groupId);
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  return realm.removeUserFromGroupSubscriptionForGroup(
    ObjectId(userId),
    groupId?.toString()
  );
}

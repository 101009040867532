/*
Inspired from:
https://github.com/realm/realm-js/blob/main/examples/node-connection-and-error/src/atlas-app-services/getAtlasApp.ts
*/

import * as Realm from "realm-web";

import conf from "../../config";

let app = null;

export const getAtlasApp = function getAtlasApp() {
  if (!app) {
    app = new Realm.App({ id: conf.CONFIG_REALM_APP_ID });
  }

  return app;
};

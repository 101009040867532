import { useState, useEffect, useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { addDays, differenceInSeconds } from "date-fns";
import conf from "../config";
import { logger } from "../services/logger";
import { appBuild } from "../services/os";
import MongodbContext from "../context/mongodbContext";
import InternetStatusContext from "../context/internetStatusContext";

/* Search replace to perform to switch web/electron: /services/web/ */
import {
  realmApp,
  openRealm,
  loginCustomJwt,
  loginAnonymous,
  getValidAccessToken,
} from "../services/web/realm";

export const MongodbProvider = ({ children }) => {
  // console.log("In MongodbProvider");
  const { userLoggedIn, user, userLogout } = useSelector((state) => ({
    userLoggedIn: state.Auth.userLoggedIn,
    user: state.Auth.user,
    userLogout: state.Auth.userLogout,
  }));
  const isOnline = useContext(InternetStatusContext);
  const [, setCookie, removeCookie] = useCookies(["CONFIG_AUTH_TOKEN_KEY"]);
  const [realm, setRealm] = useState(null);
  const [realmLoading, setRealmLoading] = useState(false);

  const logoutAllUsersAndRemoveAuthorization = useCallback(() => {
    // console.log("In logoutAllUsersAndRemoveAuthorization");
    try {
      for (const userId in realmApp.allUsers || []) {
        const user = realmApp.allUsers[userId];
        if (user.isLoggedIn) user.logOut();
      }
      removeCookie(conf.CONFIG_AUTH_TOKEN_KEY, {
        // The path must be passed otherwise it won't be removed
        path: "/",
      });
      if (conf.CONFIG_APP_BUILD !== appBuild.WEB) {
        window.localStorage.removeItem(conf.CONFIG_AUTH_TOKEN_KEY);
      }
    } catch (error) {
      logger.error(error);
    }
  }, [removeCookie]);

  const createAuthTokenCookie = useCallback(
    (token) => {
      // console.log("In createAuthTokenCookie");
      const now = new Date();
      const cookieExpiry = addDays(now, parseInt(conf.CONFIG_SESSION_DAYS));

      setCookie(conf.CONFIG_AUTH_TOKEN_KEY, token, {
        path: "/",
        expires: cookieExpiry,
        maxAge: differenceInSeconds(cookieExpiry, now),
        // Does not seem to have any effect
        // https://github.com/bendotcodes/cookies/issues/272
        // sameSite: false, (boolean|none|lax|strict): Strict or Lax enforcement
        // Does not work on Safari
        // secure: true,
      });
      if (conf.CONFIG_APP_BUILD !== appBuild.WEB) {
        window.localStorage.setItem(conf.CONFIG_AUTH_TOKEN_KEY, token);
      }
    },
    [setCookie]
  );

  const refreshLoginRealmUser = useCallback(
    async (apiUserLoggedIn) => {
      // console.log("In refreshLoginRealmUser");
      // console.log("apiUserLoggedIn", apiUserLoggedIn);
      if (realmApp && realmApp.currentUser && realmApp.currentUser.isLoggedIn) {
        // console.log("Passe 1");
        // console.log("----> realmApp.currentUser", realmApp.currentUser);
        if (conf.CONFIG_APP_BUILD !== appBuild.WEB && realm === null) {
          // console.log("Passe 11");

          setRealmLoading(true);
          try {
            const data = await openRealm(realmApp.currentUser);
            setRealm(data);
            setRealmLoading(false);
          } catch (error) {
            logger.error(error);
            logoutAllUsersAndRemoveAuthorization();
            setRealmLoading(false);
          }
        } else if (conf.CONFIG_APP_BUILD === appBuild.WEB) {
          // console.log("Passe 12");
          if (apiUserLoggedIn === true) {
            // console.log("Passe 121");
            if (realmApp.currentUser.providerType === "custom-token") {
              // console.log("Passe 1212");
              try {
                /* Verify if token is not stale */
                const token = await getValidAccessToken(realmApp.currentUser);
                if (!token) logoutAllUsersAndRemoveAuthorization();
              } catch (error) {
                logger.error(error);
                logoutAllUsersAndRemoveAuthorization();
              }
            } else {
              // console.log("Passe 122");
              setRealmLoading(true);
              logoutAllUsersAndRemoveAuthorization();
              if (isOnline === true && user && user["token"]) {
                // console.log("Passe 1221");
                try {
                  // We can't log in while offline
                  const realmUser = await loginCustomJwt(user["token"]);
                  if (realmUser.id === realmApp.currentUser.id) {
                    // console.log("Passe 12211");
                    // console.log("user[token]", user["token"]);
                    // console.log("new logged in realmUser", realmUser);
                    // We are in WEB, no need to openRealm
                    // const data = await openRealm(realmUser);
                    // setRealm(data); //on pourrait eviter ca en web
                    createAuthTokenCookie(user["token"]);
                    setRealmLoading(false);
                  } else {
                    // console.log("Passe 12212");
                    logoutAllUsersAndRemoveAuthorization();
                    logger.warn("realmAppCustomJwtLogin: user mismatch.");
                    setRealmLoading(false);
                  }
                } catch (error) {
                  logger.error(error);
                }
              }
            }
          }
        }
      } else if (realmApp && isOnline === true) {
        // console.log("Passe 2");
        setRealmLoading(true);

        if (user && user["token"]) {
          // console.log("Passe 21");
          try {
            // We can't log in while offline
            const realmUser = await loginCustomJwt(user["token"]);
            if (realmUser.id === realmApp.currentUser.id) {
              // console.log("Passe 211");
              if (conf.CONFIG_APP_BUILD !== appBuild.WEB) {
                // console.log("Passe 2111");
                const data = await openRealm(realmUser);
                setRealm(data);
              }

              createAuthTokenCookie(user["token"]);
            } else {
              // console.log("Passe 2112");
              logger.error("realmAppCustomJwtLogin: user mismatch.");
              logoutAllUsersAndRemoveAuthorization();
            }
          } catch (error) {
            logger.error(error);
          }
          /* DO NOT USE ANONYMOUS LOGIN ON ELECTRON */
        } else if (conf.CONFIG_APP_BUILD === appBuild.WEB) {
          // console.log("Passe 22");
          try {
            // This is only to be able to access a glossary as a guest
            const realmUser = await loginAnonymous();
            if (realmUser.id !== realmApp.currentUser.id) {
              // console.log("Passe 221");
              logger.error("realmAppCustomJwtLogin: user mismatch.");
              logoutAllUsersAndRemoveAuthorization();
            }
          } catch (error) {
            logger.error(error);
          }
        }
        setRealmLoading(false);
      } else {
        // console.log("Passe 3");
        logoutAllUsersAndRemoveAuthorization();
      }
    },
    [
      user,
      isOnline,
      realm,
      logoutAllUsersAndRemoveAuthorization,
      createAuthTokenCookie,
    ]
  );

  const logoutRealmUser = useCallback(async () => {
    // console.log("In logoutRealmUser");
    logoutAllUsersAndRemoveAuthorization();
  }, [logoutAllUsersAndRemoveAuthorization]);

  useEffect(() => {
    // console.log("In useEffect1");
    refreshLoginRealmUser(userLoggedIn);
  }, [userLoggedIn, refreshLoginRealmUser]);

  useEffect(() => {
    // console.log("In useEffect2");

    let isCancelled = false;

    if (isCancelled === false) {
      if (userLogout === true) logoutRealmUser();
    }

    return () => {
      isCancelled = true;
    };
  }, [userLogout, logoutRealmUser]);

  const renderRealmLoading = () => {
    return (
      <div className="d-flex justify-content-center">
        <p>Loading database, please wait...</p>
      </div>
    );
  };

  if (realmLoading === true) return renderRealmLoading();

  return (
    <MongodbContext.Provider
      value={{
        realm,
        realmApp,
        providerType: realmApp?.currentUser?.providerType,
      }}
    >
      {children}
    </MongodbContext.Provider>
  );
};

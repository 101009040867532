import conf from "../config";
import { LogLevel } from "./logger";

/** The App environment */
export type Environment =
  | "development"
  | "qa"
  | "production"
  | "production_debug";

export const LOG_LEVEL: LogLevel =
  conf.CONFIG_APP_ENV === "production" ? "warn" : "log";
export const USE_SENTRY: Boolean =
  conf.CONFIG_APP_ENV === "production" ? true : false;

// Web SDK
import * as Realm from "realm-web";

import { logger } from "../logger";
import { getAtlasApp } from "./atlasApp";

export const realmApp = getAtlasApp();

export function loginCustomJwt(jwt) {
  return new Promise(async (resolve, reject) => {
    try {
      /* Create a Custom JWT credential */
      const credentials = Realm.Credentials.jwt(jwt);
      const user = await realmApp.logIn(credentials);
      // `App.currentUser` updates to match the logged in user
      // console.assert(user.id === realmApp.currentUser.id);
      resolve(user);
    } catch (error) {
      logger.error(error);
      reject(null);
    }
  });
}

export function loginAnonymous() {
  return new Promise(async (resolve, reject) => {
    try {
      /* Create an anonymous credential */
      const credentials = Realm.Credentials.anonymous();
      const user = await realmApp.logIn(credentials);

      resolve(user);
    } catch (error) {
      logger.error(error);
      reject(null);
    }
  });
}

// Gets a valid user access token to authenticate requests
// https://www.mongodb.com/docs/realm/web/authenticate/#custom-jwt
export function getValidAccessToken(user) {
  if (!user) Promise.resolve(null);

  // An already logged in user's access token might be stale. To
  // guarantee that the token is valid, refresh it if necessary.
  return new Promise(async (resolve, reject) => {
    try {
      await user.refreshAccessToken();
      resolve(user.accessToken);
    } catch (error) {
      logger.error(error);
      reject(null);
    }
  });
}

export function openRealm(realmUser) {
  return Promise.resolve(null);
}

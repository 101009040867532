import conf from "../../config";
import { appBuild } from "../../services/os";

const ExternalLink = (props) => {
  const { children, url, className, target, rel } = props;

  const handleButtonClick = () => {
    if (url) require("electron").shell.openExternal(url);
  };

  if (!url) return null;

  return conf.CONFIG_APP_BUILD !== appBuild.WEB ? (
    <button
      type="button"
      className="btn btn-link"
      onClick={handleButtonClick}
      rel="noreferrer"
    >
      {children}
    </button>
  ) : (
    <a
      href={url}
      rel={rel || "noreferrer"}
      target={target || "_blank"}
      className={className || ""}
    >
      {children}
    </a>
  );
};

export default ExternalLink;

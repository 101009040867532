import conf from "../config";
import { appBuild } from "../services/os";
import ElectronRoutes from "./ElectronRoutes";
import WebRoutes from "./WebRoutes";

const Routes = () => {
  if (conf.CONFIG_APP_BUILD !== appBuild.WEB) return <ElectronRoutes />;

  return <WebRoutes />;
};

export default Routes;

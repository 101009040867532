import * as Sentry from "@sentry/react";
import { LOG_LEVEL, USE_SENTRY } from "./environment";

/*
  This has been taken from
  https://meticulous.ai/blog/getting-started-with-react-logging/
*/

/** Signature of a logging function */
export interface LogFn {
  (message?: any, ...optionalParams: any[]): void;
}

/** Basic logger interface */
export interface Logger {
  info: LogFn;
  warn: LogFn;
  error: LogFn;
}

/** Log levels */
export type LogLevel = "info" | "warn" | "error";

const NO_OP: LogFn = (message?: any, ...optionalParams: any[]) => {};

/** Logger which outputs to the browser console */
export class ConsoleLogger implements Logger {
  readonly info: LogFn;
  readonly warn: LogFn;
  readonly error: LogFn;

  constructor(options?: { level?: LogLevel; useSentry: Boolean }) {
    const { level, useSentry } = options || {};

    this.error = useSentry
      ? (message) => Sentry.captureMessage(message, "error")
      : console.error.bind(console);

    if (level === "error") {
      this.warn = NO_OP;
      this.info = NO_OP;
      return;
    }

    this.warn = useSentry
      ? (message) => Sentry.captureMessage(message, "warning")
      : console.warn.bind(console);

    if (level === "warn") {
      this.info = NO_OP;

      return;
    }

    this.info = console.log.bind(console);
  }
}

export const logger = new ConsoleLogger({
  level: LOG_LEVEL,
  useSentry: USE_SENTRY,
});
